
  import { Component, Prop } from 'vue-property-decorator'
  import { mapGetters } from 'vuex'
  import { Stock } from '@/entities/sales'
  import { Form } from '@/entities/public/Resource/metadata'
  import { fixPrice } from '@/utils/general'
  import FileDetail from '@/components/core/files/FileDetail.vue'
  import StockPhotos from '@/components/toolkit/details/row/custom/StockPhotos.vue'
  import InitialFields from '@/components/forms/fields/InitialFields.vue'
  import DocumentationSupervisorPanel
    from '@/components/forms/inspection/supervisor/panel/DocumentationSupervisorPanel.vue'
  import { plainToInstance } from 'class-transformer'
  import { Inspection } from '@/entities/purchase'
  import FileView from '@/components/core/files/FileView.vue'
  import { StockDetailView } from '@/components/forms/view/StockDetailView'

  const { VUE_APP_WEB_URL } = process.env

@Component({
  components: {
    FileView,
    DocumentationSupervisorPanel,
    InitialFields,
    StockPhotos,
    FileDetail,
    StockCell: () => import('@/components/dataTables/cell/StockCell.vue'),
    Quotas: () => import('@/components/forms/fields/Quotas.vue'),
    ChartRenderer: () => import('@/components/dashboards/ChartRenderer.vue'),
    GDetail: () => import('@/components/core/view/GDetail.vue'),
  },
  computed: {
    ...mapGetters('user', ['roles', 'defaultRole']),
  },
})
  export default class GenericStockDetail extends StockDetailView {
  @Prop({ type: [String, Number] }) stockId!: any
  @Prop({ type: [String, Number] }) idInspection!: any
  thumbnails = []
  stock: Stock = {} as Stock
  inspection: Inspection = plainToInstance(Inspection, {})
  metadata = {} as Form
  charts = []
  roles!: string[]
  defaultRole!: string
  displayDetails = false
  snackbar = false
  openDocuments = false
  documentation = []
  componentDocumentation = []
  link0Km = ''
  photoProperties = {
    properties: {
      accept: null,
      multiple: false,
      fileTypeId: null,
      name: '',
    },
  }

  idProcessInspectedComponent = null
  enablementInspectionComponent = []
  socialNetworks: Record<string, any> = {}
  socialNetworksKeys = []
  pie = { amount: 0, perc: 0 }
  title = 'Detalle del vehículo'

  get displayCharts () {
    const { roles, defaultRole } = this

    return defaultRole === 'admin' || roles.some(r => r.slug.includes('supervisor'))
  }

  get price () {
    const { stock: { currentPrice } } = this
    return fixPrice(currentPrice?.amount)
  }

  get favorites () {
    const { stock: { viewDetails: { auto: { generation, version } } } } = this

    return generation?.attributes?.filter(({ base: { favorite } }) => favorite) || version?.attributes?.filter(({ base: { favorite } }) => favorite)
  }

  get isDisabled () {
    const { stock } = this
    return Boolean(stock.status.isSold) || Boolean(stock.status.isEnabling)
  }

  async created () {
    const { stockId: id } = this

    this.stock = await this.fetchData({ query: { model: 'Stock', name: 'fetch' }, params: { id } })
    let generation
    if (this.stock.viewDetails.auto?.generation?.id) {
      generation = await this.fetchData({
        query: { model: 'Generation', name: 'fetch', params: { id: this.stock.viewDetails.auto.generation.id } },
      })
    }

    const version = await this.fetchData({
      query: { model: 'VersionYear', name: 'fetch', params: { id: this.stock.viewDetails.auto.version.id } },
    })

    this.stock.viewDetails.auto.version.attributes = version?.attributes
    this.stock.viewDetails.auto.generation = generation
    this.stock.transferCost = await this.calculateTransfer(id)
    this.displayDetails = true
    if (this.stock.metadata?.socialNetworks) {
      this.setSocialNetworksForm(this.stock.metadata?.socialNetworks)
    }
    const mileagePrices = await this.fetchData({
      query: { model: 'MileagePrice', name: 'find' },
      filter: { id_version_year: { _eq: this.stock.auto.version.id } },
    })

    const mileagePrice = mileagePrices?.find((item: any) => item.mileageFrom === 0 && item.mileageTo === 0 && item.dateTo === null)
    this.link0Km = mileagePrice?.link

    await this.getFiles(this.stock)
  }

  async getFiles (stock) {
    const { viewDetails: { idEnablement } } = stock

    this.componentDocumentation = await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Documentación' } },
    })
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection_inspected_component' } },
    })
    this.idProcessInspectedComponent = process?.[0]?.id
    this.enablementInspectionComponent = await this.fetchData({
      query: { name: 'find', model: 'InspectionQualification' },
      filter: {
        component: {
          _and: [
            { id_process_record: { _eq: idEnablement } },
            { process: { table_name: { _eq: 'enablement' } } },
            {
              _or: [
                { inspection_component: { component: { category: { name: { _in: ['Documentación', 'Identificación'] } } } } },
                { inspection_component: { component_value: { value: { _eq: 'Llaves' } } } },
              ],
            },
          ],
        },
      },
      force: true,
    })
    this.inspection.inspectedComponents = this.enablementInspectionComponent
    if (this.circulationPermit?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(this.circulationPermit[0].idInspectedComponent, 'circulationPermit', process?.[0]?.id)
    }
    if (this.soap?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(this.soap[0].idInspectedComponent, 'soap', process?.[0]?.id)
    }
    if (this.technicalReview?.[0]?.idInspectedComponent) {
      await this.setPhotoToForm(this.technicalReview[0].idInspectedComponent, 'technicalReview', process?.[0]?.id)
    }

    await this.getOtherDocuments()
    await this.getMaintenance()
  }

  async getMaintenance () {
    const idAuto = this.stock?.auto?.id
    const maintenances = await this.fetchData({
      query: { name: 'find', model: 'Maintenance' },
      filter: { auto: { id: { _eq: idAuto } } },
      force: true,
    })

    const maintenanceProcess = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'maintenance' } },
    })

    const photos = await Promise.all(maintenances.map(async maintenance => {
      return this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: maintenance.id } },
            { parameter: { process: { id: { _eq: maintenanceProcess?.[0]?.id } } } },
            { parameter: { file_type: { name: { _eq: 'photo' } } } },
          ],
        },
        force: true,
      })
    }))

    this.documentation.push({ fieldKey: 'maintenances', photo: photos.flat() })
  }

  async getOtherDocuments () {
    const { stock } = this
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'negotiation' } },
    })
    const { cav, report, penalties } = await this.findAssociatedFiles(process?.[0]?.id, stock.viewDetails.idNegotiation)

    this.documentation.push({ fieldKey: 'cav', photo: cav })
    this.documentation.push({ fieldKey: 'report', photo: report })
    this.documentation.push({ fieldKey: 'penalties', photo: penalties })
  }

  async findAssociatedFiles (idProcess, id) {
    if (!id) return {}
    const cav = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { name: { _eq: 'cav' } } }] },
      force: true,
    })

    const report = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { name: { _eq: 'legal_report' } } }] },
      force: true,
    })

    const penalties = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { name: { _eq: 'penalty_certificate' } } }] },
      force: true,
    })

    return {
      cav,
      report,
      penalties,
    }
  }

  async setPhotoToForm (idProcessRecord, fieldKey, idProcess) {
    if (!idProcessRecord) return

    const photo = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: idProcessRecord } },
          { parameter: { process: { id: { _eq: idProcess } } } },
          { parameter: { file_type: { name: { _eq: 'photo' } } } },
        ],
      },
      force: true,
    })

    this.documentation.push({ fieldKey, photo })
  }

  get circulationPermit () {
    const { enablementInspectionComponent } = this

    if (enablementInspectionComponent?.length) {
      const components = enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'circulation_permit')

      if (components.length) {
        return components
      }
    }

    return null
  }

  get soap () {
    const { enablementInspectionComponent } = this

    if (enablementInspectionComponent?.length) {
      const components = enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'soap')
      if (components.length) {
        return components
      }
    }

    return null
  }

  get technicalReview () {
    const { enablementInspectionComponent } = this

    if (enablementInspectionComponent?.length) {
      const components = enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'technical_review')
      if (components.length) {
        return components
      }
    }

    return null
  }

  setSocialNetworksForm (networks) {
    const keys = Object.keys(networks)
    for (const key of keys) {
      if (networks[key]?.url) {
        this.$set(this.socialNetworks, key, { url: '' })
        this.socialNetworksKeys.push(key)
        this.socialNetworks[key].url = networks[key].url
      }
    }
  }

  async mounted () {
    const { title } = this
    const { metadata } = this.getForm('Stock', 'stock')
    const { form } = metadata as Form

    this.title = form.title

    if (!this.isBreadCrumbPresent(title)) {
      this.setCustomCrumbs(metadata, title)
    }
  }

  loadDetails (metadata) {
    this.metadata = metadata

    if (this.displayCharts) {
      this.charts = metadata.aggregates
    }
  }

  openNewVehicle () {
    const { stock, link0Km } = this

    const url = link0Km || stock.auto.version.version.model?.officialWeb || stock.auto.version.version.model?.brand?.officialWeb

    return this.openLink(url)
  }

  async goWebSite () {
    const { stock } = this
    const car = await this.fetchData({
      query: { model: 'Car', name: 'find' },
      filter: { auto: { id: { _eq: stock.auto.id } } },
    })

    if (!car.length) return
    const url = `${VUE_APP_WEB_URL}${car[0].id}`
    window.open(url, '_blank')
  }

  goMercadoLibre () {
    const { stock } = this

    const url = 'https://autos.mercadolibre.cl/marca/modelo'
    this.openPortal(url, stock, false)
  }

  findFile (key) {
    return this.documentation?.find(doc => doc.fieldKey === key)
  }

  async copyToClipboard () {
    const { stock } = this

    const files = {
      circulationPermit: this.findFile('circulationPermit'),
      soap: this.findFile('soap'),
      technicalReview: this.findFile('technicalReview'),
      cav: this.findFile('cav'),
    }
    await this.copyVehicleToClipboard(stock, files)
    this.snackbar = true
  }
  }
